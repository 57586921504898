<template>
  <ul class="team">
    <li v-for="member in members" :key="member.id">
      <div
        v-if="member.portrait || member.portraitAnimation"
        class="team__portrait"
      >
        <img
          v-if="member.portrait"
          class="team__overlay"
          :src="member.portrait.url"
          :alt="member.name"
          :width="member.portrait.width"
          :height="member.portrait.height"
        />
        <img
          v-if="member.portraitAnimation"
          class="team__image"
          :src="member.portraitAnimation.url"
          :alt="member.name"
          :width="member.portrait.width"
          :height="member.portrait.height"
        />
      </div>
      <span class="name">{{ member.name }}</span>
      <span class="function">{{ member.function }}</span>
      <a v-if="member.portraitPdf" :href="member.portraitPdf" class="pdf">
        Portrait
      </a>
      <a v-if="member.email" :href="'mailto:' + member.email" class="email">
        {{ member.email }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['members'],
};
</script>

<style lang="scss" scoped>
.team {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem 0 0 0;
  padding: 0 0.75rem;

  li {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 20%;
    text-align: left;
    padding-right: 0.5rem;
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.2;
    margin: 0.75rem 0;
    position: relative;

    &:hover {
      .team__overlay {
        opacity: 0;
      }
      .team__image {
        display: block;
      }
    }
  }
  .function,
  .pdf {
    font-size: 0.7rem;
  }
  .pdf {
    text-decoration: underline;
  }
  .email {
    font-size: 0.7rem;
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline;
    }
  }
  &__portrait {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 90%;
    margin-top: -0.5rem;
  }
  &__image,
  &__overlay {
    position: absolute;
    top: 10%;
    left: 0;
    width: 80%;
    height: 90%;
  }
  &__image {
    display: none;
  }

  @include bp-m() {
    li {
      width: 25%;
    }
  }
  @media screen and (max-width: 850px) {
    li {
      width: 33.3%;
    }
  }
  @media screen and (max-width: 550px) {
    margin: 1rem 0;

    li {
      width: 50%;
      margin: 0.5rem 0;
    }
  }
}
</style>