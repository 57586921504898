<template>
  <div class="spc__newsletter">
    <!--<h2 class="spc__newsletter__headline">Newsletter</h2>-->
    <div v-html="block.text"></div>
    <div class="spc__newsletter__form">
      <form
        :action="mailchimpUrl"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <input
          type="text"
          value=""
          name="FNAME"
          :placeholder="$t('firstname')"
          id="mce-FNAME"
        />
        <input
          type="text"
          value=""
          name="LNAME"
          :placeholder="$t('lastname')"
          id="mce-LNAME"
        />
        <input
          type="email"
          value=""
          name="EMAIL"
          :placeholder="$t('email')"
          id="mce-EMAIL"
        />
        <div style="position: absolute; left: -5000px" aria-hidden="true">
          <input
            type="text"
            name="b_ac82b5612d71dab97ac3a3506_b994593783"
            tabindex="-1"
            value=""
          />
        </div>
        <button type="submit" name="subscribe" id="mc-embedded-subscribe">
          {{ $t('subscribe') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsletterForm',
  props: ['block'],
  computed: {
    mailchimpUrl() {
      switch (this.block.audience.value) {
        case 'skkg':
          return 'https://skkg.us17.list-manage.com/subscribe/post?u=ac82b5612d71dab97ac3a3506&amp;id=b994593783';
          break;
        case 'campo':
          return 'https://skkg.us20.list-manage.com/subscribe/post?u=5e73f4fc6af735813485d63dd&amp;id=3ddd8c9744';
          break;
        default:
          return 'https://skkg.us17.list-manage.com/subscribe/post?u=ac82b5612d71dab97ac3a3506&amp;id=b994593783';
      }
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
</style>
