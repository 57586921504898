<template>
  <div class="news">
    <article
      v-for="article in filteredArticles"
      :key="article.id"
      class="news__article"
    >
      <span class="news__date">{{ article.date }}</span>
      <h3>{{ article.title }}</h3>
      <div class="news__text" v-html="article.text"></div>
      <a
        v-for="link in article.downloads"
        target="_blank"
        :key="link.id"
        :title="link.title"
        :href="link.url"
        class="news__link"
      >
        Download PDF
      </a>
    </article>
    <a href="#" @click.prevent="showAll = !showAll" class="news__morelink">
      {{ showAll ? $t('lessnews') : $t('morenews') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'News',
  props: ['articles'],
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    filteredArticles() {
      if (this.showAll) return this.articles;
      return this.articles.slice(0, 8);
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.news {
  max-width: 50%;
  margin: 2rem 0;
  padding: 0 0.75rem;

  @include bp-xs() {
    padding: 0 0.5rem;
  }

  &__article {
    margin-bottom: 5rem;

    @include bp-s() {
      margin-bottom: 3rem;
    }
  }
  p {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }
  p + ul {
    margin-top: 1rem;
  }
  li {
    line-height: 1.3;
    position: relative;
    padding-left: 2rem;

    &::before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='27' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%231D1D1B' stroke-width='.8' fill='none' fill-rule='evenodd'%3E%3Cpath d='M18.57 15.943l7.33-7.33-7.33-7.33M1 8.612h24.668'/%3E%3C/g%3E%3C/svg%3E");
      background-size: 100%;
      background-position: 0 50%;
      background-repeat: no-repeat;
      width: 1.2rem;
      height: 1.2rem;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    text-decoration: underline;
  }
  h3 {
    font-weight: bold;
    line-height: 1.25;
    margin: 0.25rem 0 1rem 0;
  }
  &__text {
    margin: 1rem 0;
  }
  &__link {
    display: block;
    margin: 0.5rem 0;
    position: relative;
    padding-left: 2rem;
    text-decoration: underline;

    &::before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='27' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%231D1D1B' stroke-width='.8' fill='none' fill-rule='evenodd'%3E%3Cpath d='M18.57 15.943l7.33-7.33-7.33-7.33M1 8.612h24.668'/%3E%3C/g%3E%3C/svg%3E");
      background-size: 100%;
      background-position: 0 50%;
      background-repeat: no-repeat;
      width: 1.2rem;
      height: 1rem;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:hover {
      text-decoration: underline;
    }

    @include bp-s() {
      &::before {
        height: 1rem;
        background-position: 0 50%;
      }
    }
  }
  &__morelink {
    display: inline-block;
    margin-top: 2rem;
  }

  @include bp-s() {
    max-width: none;
    margin: 1rem 0;
  }
}
</style>
