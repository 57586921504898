<template>
  <div>
    <template v-for="block in blocks">
      <h2 v-if="block.type == 'sectionTitle'"
          :key="block.id"
          class="subpage__sectiontitle font-spitzmarke">
        {{ block.title }}
      </h2>
      <span v-if="block.type == 'anchorPoint'"
            :key="block.id"
            :id="block.title"></span>
      <div v-if="block.type == 'singleColumnLayout'"
           class="subpage-section__singlecol"
           :key="block.id">
        <SubpageContent :content="block.content" />
      </div>
      <div v-if="block.type == 'twoColumnLayout'"
           class="subpage-section__dualcol"
           :key="block.id">
        <div class="col col--left">
          <SubpageContent :content="block.content.left" />
        </div>
        <div class="col col--right"
             :class="{ empty: block.content.right.length == 0 }">
          <SubpageContent :content="block.content.right" />
        </div>
      </div>
      <TeamMembers v-if="block.type == 'teamMembers'"
                   class="subpage-section__team"
                   :key="block.id"
                   :members="block.members" />
      <News v-if="block.type == 'newsArticles'"
            :key="block.id"
            :articles="block.articles"></News>
      <blockquote v-if="block.type == 'quote'"
                  class="subpage-section__quote"
                  :key="block.id">
        <p>{{ block.text }}</p>
        <small>{{ block.author }}</small>
      </blockquote>
      <hr v-if="block.type == 'horizontalLine'"
          :key="block.id"
          class="subpage-section__hr">
    </template>
  </div>
</template>

<script>
import SubpageContent from '@/components/SubpageContent';
import News from '@/components/News';
import TeamMembers from '@/components/TeamMembers';

export default {
  name: 'SectionContent',
  props: ['blocks'],
  components: {
    SubpageContent,
    News,
    TeamMembers,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
.subpage-section__singlecol {
  max-width: 50%;
  margin: 2rem 0;

  @include bp-s() {
    max-width: none;
    margin: 1rem 0;
  }
}

.subpage-section__dualcol {
  margin: 2rem 0;
  display: flex;

  .col {
    width: 50%;
    flex-shrink: 0;
    box-sizing: border-box;

    &:first-child {
      padding-right: 1rem;
    }

    &--right {
      // padding-top: 0.15rem;
    }
  }

  @include bp-s() {
    flex-wrap: wrap;
    margin: 1rem 0;

    .col {
      width: 100%;
      margin: 0.5rem 0;

      &:first-child {
        padding-right: 0;
      }

      &--right {
        margin-top: 3rem;

        &.empty {
          margin-top: 0;
          margin-bottom: 0;
        }

        &--right {
          padding-top: 0;
        }
      }
    }
  }
}

.subpage__sectiontitle {
  margin-bottom: -1rem;
  margin-top: 6rem;
  padding: 0 0.75rem;

  &:first-child {
    margin-top: 1rem;
  }

  @include bp-s() {
    margin-top: 3rem;
    padding: 0 0.5rem;

    &:first-child {
      margin-top: -1rem;
    }
  }
}

.subpage-section__quote {
  padding: 0 1rem;
  text-align: center;
  margin: 4rem auto 8rem auto;
  max-width: 27rem;

  p {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  small {
    display: block;
    margin: 0 auto;
    line-height: 1.25;
    font-size: 1rem;
    width: 66.6%;
    // hyphens: auto;
  }

  &:first-child {
    margin-top: 0;
  }

  @include bp-s() {
    margin: 2rem auto 4rem auto;

    small {
      width: 100%;
    }
  }
}

.subpage-section__hr {
  height: 2px;
  background: #000;
  border: none;
  display: block;
  margin: 5rem 0;

  @include bp-s() {
    margin: 3rem 0;
  }
}
</style>
